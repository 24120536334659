// Caixa serviço
.caixa-servico {
  overflow: hidden;
  position: relative;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  &.pagina{
    margin-bottom: 20px;

    .segura-conteudo-servicos{
      display: flex;
      vertical-align: middle;
      align-items: center;
      height: 232px;  
      margin-top: 12px; 

        // TABLET
        @include mq(m) {
          height: 180px;
          margin-top: 25px;
        }
        
        // MOBILE
        @include mq(s) {
          height: 230px;
          margin-top: 8px;
        }    
      }
    }

    // MOBILE
    @include mq(s) {
      box-shadow: none;
      border-radius: 0px;
      margin-bottom: 40px;
    }

    // Foto
    figure {
      overflow: hidden;

        // MOBILE
        @include mq(s) {
          border-radius: 0px;
          box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        }

        img {
          transition: .3s ease-out;
        }
      }
      &:hover figure img {
        transform: scale(1.1);
      }

    // Fundo
    span {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      float: left;
      width: 100%;
      background: rgba(#000, .7);
      text-align: center;
      transition: .3s;
      padding: 10px;

      .segura-conteudo-servicos{
        display: flex;
        vertical-align: middle;
        align-items: center;
        height: 192px;  
        margin-top: 12px;

        // TABLET
        @include mq(m) {
          height: 220px;
          margin-top: 25px;
        }
        
        // MOBILE
        @include mq(s) {
          height: 160px;
          margin-top: 8px;
        }  

        div{
          float: left;
          width: 100%;
        }    
      }

    }
    &:hover span {
      background: rgba(#000, .65);
    }

    // Título
    h3 {
      float: left;
      width: 100%;
      padding: 0 10px;
      text-align: center;
      font-family: $fonte_geral;
      color: #FFF;
      font-size: 18px;
      line-height: 25px;
      text-transform: uppercase;
      text-shadow: 2px 2px 2px rgba(#000, .3);
      margin-bottom: 20px;

        // TABLET
        @include mq(m) {
          font-size: 18px;
          margin-bottom: 20px;
        }
        
        // MOBILE
        @include mq(s) {
          color: $cor_fonte_escura;
          font-size: 16px;
          line-height: 26px;
          text-shadow: none;
          margin-bottom: 10px;
        }
      }

    // Botão
    .btn {
      transition: .3s ease-out;
      opacity: 0;
      transform: translate(0, 20px);   

        // MOBILE
        @include mq(s) {
          opacity: 1 !important;
          transform: translate(0, 0) !important;
          font-size: 14px;
        }
      }
      &:hover .btn {
        opacity: 1;
        transform: translate(0, 0);      
      }

    }

// Carrosel
.carrosel-servicos {
  .caixa-servico {
    display: block;
    width: 100%;
    margin: 10px;
  }
}
