// Galeria caixa
.caixa-galeria {
	margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  text-align: center;

	// Foto
	figure {
		float: left;
		margin-bottom: 10px;
		overflow: hidden;
    position: relative;

    // Fundo
    span {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      float: left;
      width: 100%;
      background: rgba($cor_primaria, .3);
      text-align: center;
      transition: .3s;  
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 2;
        top: 30px;
        right: 30px;
        bottom: 30px;
        left: 30px;
        border: 1px solid rgba($cor_secundaria, .6);

        // MOBILE
        @include mq(s) {
            top: 20px;
            right: 20px;
            bottom: 20px;
            left: 20px;
            border-size: 2px;
        }
    }

    img {
     transition: .3s ease-out;
   }
 }
 &:hover {
  figure img {
   transform: scale(1.1);
 }
}
&:hover figure span {
  background: rgba($cor_primaria, .4);
}

	// Título
	h2 {
		float: left;
		height: 40px;
		width: 100%;		
		margin-bottom: 10px;
		color: $cor_fonte_escura;
		font-size: 18px;
		transition: .3s ease-out;

		// MOBILE
		@include mq(s) {
			font-size: 15px;
			height: 40px;
		}

		// TABLET
		@include mq(m) {
			height: 40px;
		}
	}
	&:hover {
		h2 {
			color: $cor_secundaria;
		}
	}
}

// Lista de fotos detalhe
.galeria-lista {
	float: left;
	width: 100%;
	margin-top: 20px;
}

// Galeria caixa detalhe
.galeria-thumb {
	display: inline-block;	
	overflow: hidden;
	border: 0px solid $cor_secundaria;
	margin-bottom: 14px;
  position: relative;

  // Fundo
  span {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    float: left;
    width: 100%;
    background: rgba($cor_primaria, .3);
    text-align: center;
    transition: .3s;  
  }
  &:hover span {
    background: rgba($cor_primaria, .4);
  }

  img {
    transition: .3s ease-out;
  }
  &:hover {
    img {
     transform: scale(1.1);
   }
 }
}
