// Seção
.home-blog {
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  background-blend-mode: saturation;

    // Máscara
    .mascara {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, .9);
    }

  }

// Bloco post destaque
.bloco-post-dest {
  position: relative;   

    // MOBILE
    @include mq(s) {
      height: auto;
      margin-bottom: 20px;

      &:last-child{
        margin-bottom: 0px;
      }
    }

    // TABLET
    @include mq(m) {
    }

    // Foto
    figure {
      overflow: hidden;
        //box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        position: relative;   

        img {
          transition: .3s ease-out;
          //filter: grayscale(100%);
        }
      }
      &:hover figure img {
        transform: scale(1.1);
      }

    // Descrição
    > div {
      position: relative;
      z-index: 2;
      width: 100%;
      margin: 0 auto;
      margin-top: 0px;
      padding: 20px;
      background-color: rgba(#000, 0.7);
    }

    // Fundo
    .fundo-destaque-blog {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      float: left;
      width: 100%;
      background: rgba($cor_primaria, .3);
      text-align: center;
      transition: .3s;
    }
    &:hover .fundo-destaque-blog {
      background: rgba($cor_primaria, .4);
    }

    // Título
    h2 {
      font-size: 16px;
      line-height: 22px;
      color: $cor_fonte_escura;
      font-weight: bold;
      margin-bottom: 10px;
      transition: .3s ease-out;

        // TABLET
        @include mq(m) {
         font-size: 14px;       
       }
     }

    // Texto
    h3 {
      font-size: 14px;
      line-height: 20px;
      color: $cor_fonte_clara;
      margin-bottom: 10px;
      transition: .3s ease-out;

        // TABLET
        @include mq(m) {
         font-size: 14px;       
       }
     }

     .texto-blog-destaque{

     }

    // Data
    span {
      color: $cor_fonte_clara;
      font-size: 14px;
      text-align: center;

      &.dia{
        float: left;
        width: 34px;
        text-align: center;
        font-size: 16px;
        background: $cor_secundaria;
        padding: 1px 4px;
        border-radius: 5px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;

            // TABLET
            @include mq(m) {
              margin-bottom: 5px;    
            }
            
            // MOBILE
            @include mq(s) {
             font-size: 12px;       
           }
         }

         &.mes{
          float: left;
          width: 34px;
          text-align: center;
          text-transform: uppercase;
          background: #121212;
          border-radius: 3px;
          color: #FFF;
          font-size: 12px;
          padding: 1px 4px;

            // TABLET
            @include mq(m) {
              margin-bottom: 5px;    
            }
            
            // MOBILE
            @include mq(s) {
             font-size: 12px;       
           }
         }

        // TABLET
        @include mq(m) {
         font-size: 12px;       
       }
     }
   }
