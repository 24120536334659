// Banner do topo
.institucional-banner {
	float: left;
	width: 100%;
	height: 200px;
	background-color: #DDD;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
  position: relative;

  .mask-banner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(#000, .7); 
    display: flex; 
    align-items: center; 
    justify-content: center;
  }

	// TABLET
	@include mq(m) {
		height: 150px;
	}

	// MOBILE
	@include mq(s) {
		height: 100px;
	}
}

/* Segura o texto das páginas instituicionais para evitar que fiquem com 100% de largura e prejudique a leitura */
.segura-texto {
	// DESKTOP
	@include mq(l) {
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
}

.segura-foto-redonda{
  // MOBILE
  @include mq(s) {
    text-align: center;
  }
}

.foto-sobre-redonda{
  float: left;
  border: solid 1px #121212;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  margin-right: 20px;
  margin-bottom: 5px;
  position: relative;

  // Fundo
  span {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    float: left;
    width: 100%;
    background: rgba($cor_primaria, .3);
    text-align: center;
    transition: .3s;  
  }
  &:hover span {
    background: rgba($cor_primaria, .4);
  }

  // MOBILE
  @include mq(s) {
    float: none;
    display: inline-block;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
