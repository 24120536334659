// Header principal
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z_index_header;
  float: left;
  width: 100%;
  background: $cor_primaria;
  padding: 8px 0;
  padding-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,.26);
  transition: .5s ease-out;
  border-bottom: solid 1px #121212;

	// MOBILE
	@include mq(s) {
		text-align: center;
		box-shadow: none;
    position: absolute;
	}
}

// Conteúdo (Container)
.header-content {
	padding: 0 10px;
}

// Logo
.header-logo {
	display: block;
	float: left;
	max-width: 120px;
	width: auto;

	// TABLET
	@include mq(m) {				
		max-width: 95px;
	}

	// MOBILE
	@include mq(s) {	
		float: none;
		display: inline-block;
		vertical-align: top;
		max-width: 110px;
	}
}

// Direita
.header-direita {
	float: right;
	width: auto;
  margin-top: 20px;

	// TABLET
	@include mq(m) {		
    width: auto;
    margin-top: 10px;
  }

	// MOBILE
	@include mq(s) {
		width: 100%;				
		margin-top: 10px;
	}
}

// Botões
.header-botoes {
	float: right;
	text-align: right;
	margin-left: 50px;

	// MOBILE
	@include mq(s) {
		width: 100%;
		text-align: center;
		margin-bottom: 10px;
	}

	.btn {
		// MOBILE
		@include mq(s) {
			font-size: 12px;
			padding: 10px 15px;
		}
	}
}

// Telefones
.header-telefones {
	text-align: right;
	margin-top: 5px;

	// MOBILE
	@include mq(s) {
		width: 100%;
		text-align: center;
		margin-top: 0px;
	}

	// Item
	a {
		display: inline-block;			
		margin-left: 20px;		
		font-weight: 300;
		font-size: 17px;
		text-transform: uppercase;
		color: $cor_fonte_escura;
    font-family: $fonte_geral;

		// TABLET
		@include mq(m) {				
			font-size: 15px;
		}

		// MOBILE
		@include mq(s) {
			margin: 0 3px;	
			font-size: 15px;
		}

		i {
			margin-right: 5px;
			color: $cor_secundaria;
		}
	}
}


// ---------------------------- HOME ---------------------------- //

body.home {
  padding-top: 0px;
}

// Header
.home .header:not(.active) {
  background: none;
  background-image: linear-gradient(to bottom, rgba(#000, .9), rgba(#FFF, 0));
  box-shadow: none;
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: solid 0px transparent;

  // TABLET
  @include mq(m) {
    padding-bottom: 30px;
    padding-top: 30px;
  }

  // MOBILE
  @include mq(s) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
