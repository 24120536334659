.home-projetos{
  padding: 0px;
}

.segura-projetos{
  float: left;
  width: 100%;

  .projeto-home{
    position: relative;
    float: left;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 25%;
    height: 250px;
    overflow: hidden;

    // TABLET
    @include mq(m) {
      width: 33.3%;
      height: 200px;
    }

    // MOBILE
    @include mq(s) {
      width: 50%;
      height: 150px;
    }

    figure {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      transition: .5s ease-out;
      transform: scale(1.01);
    }
    &:hover figure {
      transform: scale(1.1);
    }
    // Fundo
    span {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      float: left;
      width: 100%;
      background: rgba($cor_primaria, .3);
      text-align: center;
      transition: .3s;  
    }
    &:hover span {
      background: rgba($cor_primaria, .4);
    }
  }
}
