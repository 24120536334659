// Foto
.servico-detalhe-foto {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;

  // Fundo
  span {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    float: left;
    width: 100%;
    background: rgba($cor_primaria, .3);
    text-align: center;
    transition: .3s;  
  }
  &:hover span {
    background: rgba($cor_primaria, .4);
  }

  // TABLET
  @include mq(m) {
    padding-left: 0px;
  }

	// MOBILE
	@include mq(s) {
		margin-bottom: 20px;
    padding-left: 0px;
  }
}

// Texto
.servico-detalhe-texto {

	// BOTÃO
	.btn-container {
		float: none;

		// MOBILE
		@include mq(s) {
			text-align: center;
		}
	}
}

.linha-suave{
  width: 100%;
  height: 1px;
  background-color:  #121212;
}

.servico-lista-lateral{
  padding-right: 20px;
  // TABLET
  @include mq(m) {
    padding-right: 0px;
  }
  // MOBILE
  @include mq(s) {
    padding-right: 0px;
  }
  .servico-lista-lateral-item{
    margin-bottom: 20px;
    width: 100%;
    text-align: left;
    &:first-child{
      margin-top: -20px;
    }

    &.active{
      border-color: $cor_secundaria;
      color: $cor_secundaria;
    }
  }
}
