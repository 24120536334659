// Slide
.slide-home {
	float: left;
	width: 100%;
	
	// Modifica as cetas do SLICK para o slide
	.slick-prev,
	.slick-next {	    
		height: 35px;
		width: 35px;	 
		border-color: rgba(#FFF, .5); 
		&:hover, &:focus {	      
			border-color: $cor_secundaria;
		}
		&.slick-disabled {
			border-color: rgba(#FFF, .2);
     cursor: default !important;
   }

		// MOBILE
		@include mq(s) {	
			display: none !important;
		}
	}
	.slick-prev {
		left: 20px;
	}
	.slick-next {
		right: 20px;
	}	
}

// Slide ITEM (Background Image)
.slide-item {
	position: relative;
	display: block;
	float: left;
	width: 100%;
	height: auto;
	overflow: hidden;
	background: #FFF;
	text-align: center;
	
	&[href="#"] {
		cursor: default;
	}

	// Slide IMAGEM
	.slide-image {	
		width: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
    //background-blend-mode: saturation;
    height: 100vh;

    // TABLET
    @include mq(m) {
      height: 550px;
    }

    // MOBILE
    @include mq(s) {
      height: 480px;
    }
  }
}

// Slide ITEM (Imagem padrão) OBS: Está desabilitado por padrão
.slide-item-auto {
	position: relative;
	display: block;
	float: left;
	width: 100%;
	height: auto;
	overflow: hidden;
	background: #FFF;
	text-align: center;

	&[href="#"] {
		cursor: default;
	}

	// Slide IMAGEM
	.slide-image {	
		display: inline-block;
		vertical-align: bottom;
	}
}

// Slide MÁSCARA
.slide-mask {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(#000, .7);	
	display: flex; 
	align-items: center; 
	justify-content: center;
}

// Slide CONTEÚDO
.slide-content {
	width: 100%;
	max-width: $largura_desktop; 
  padding-top: 0px;
  padding: 0px 10px;
  text-align: left;
  display: none;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  margin-top: 60px;

	// TABLET
	@include mq(m) {
		max-width: $largura_tablet; 
	}

	// MOBILE
	@include mq(s) {
		max-width: $largura_smartphone; 
    padding: 0px 20px;
    margin-top: 90px;
  }
}

// Slide TÍTULO
.slide-titulo {	
	color: #FFF;
	font-family: $fonte_titulo;		
	font-size: 40px;
	font-weight: 300;
	line-height: 1.2;
	text-transform: uppercase; 
	margin-bottom: 15px;

	// TABLET
	@include mq(m) {			
		font-size: 24px; 
	}
	
	// MOBILE
	@include mq(s) {
		font-size: 20px; 
    margin-bottom: 10px;
  }
}

// Slide TEXTO
.slide-texto {	
	color: #FFF;
	font-family: $fonte_geral;		
	font-size: 20px;
	font-weight: 300;
	line-height: 1.4;

	// TABLET
	@include mq(m) {			
		font-size: 18px;
	}
	
	// MOBILE
	@include mq(s) {
		font-size: 16px; 
	}
}

// Slide BOTÃO
.slide-btn {
	text-align: left;
	margin-top: 40px;

  // MOBILE
  @include mq(s) {
    margin-top: 20px;
  }

  .btn {
		// MOBILE
		@include mq(s) {
			font-size: 12px;
			padding: 12px 25px;
		}
	}
}

@-webkit-keyframes slide-fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@keyframes slide-fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	
	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

.slide-fadeInUp {
	-webkit-animation-name: slide-fadeInUp;
	animation-name: slide-fadeInUp;
}
