// Footer geral
.footer {
	position: relative;
	float: left;
	width: 100%;
	padding-top: 40px;
	background: $cor_primaria;
	overflow: hidden;
  border-top: solid 1px #121212;

	// MOBILE
	@include mq(s) {
		padding-bottom: 0px;
	}
}

// Conteúdo container
.footer-content {
	margin-bottom: 40px;
}

// Coluna
.footer-bloco {

	&:not(:last-child) {
		// MOBILE
		@include mq(s) {
			margin-bottom: 40px;
		}
	}

	// Título
	> h2 {
		font-family: $fonte_titulo;
		color: #FFF;
		font-size: 24px;
		font-weight: 300;
		line-height: 1.2;
		text-transform: uppercase;
		margin-bottom: 40px;
    position: relative;

    // Linha inferior
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: -10px;
      margin: 0 auto;
      width: 100px;
      height: 2px;
      background: $cor_secundaria;
    }

		// MOBILE
		@include mq(s) {
			font-size: 20px;
		}
	}

	// Lista links
	.footer-lista {
		// Item
		li {
			float: left;
			width: 50%;
			margin-bottom: 15px;
			font-size: 16px;
			font-weight: 300;
			line-height: 1.2;
			color: #FFF;

			&:last-child {
				margin-bottom: 0px;
			}

			// TABLET
			@include mq(m) {
				width: 100%;
			}

			// MOBILE
			@include mq(s) {
				width: 100%;
			}
		}

		// Link
		a {
			color: #FFF;
			transition: .2s ease-out;

			&:hover {
				color: $cor_secundaria;
			}
		}

		// Ícone
		i {
			margin-right: 10px;
		}
	}
}

// Mapa do site
.footer-mapa {}

// Newsletter
.footer-newsletter {
	padding-right: 40px;

	// TABLET
	@include mq(m) {
		padding-right: 20px;
	}

	// MOBILE
	@include mq(s) {
		padding-right: 0px;
	}

	// Texto
	.texto {
		font-size: 16px;
		font-weight: 300;
		color: #FFF;
		margin-bottom: 20px;
	}

	// Campo
	.campo-container {
		float: left;
		width: calc(100% - 120px);
		margin: 0px;
	
		// TABLET
		@include mq(m) {
			width: 100%;
			margin-bottom: 10px;
		}

		// MOBILE
		@include mq(s) {
			width: 100%;
			margin-bottom: 10px;
		}

		// Campo
		.campo {			
			height: 40px;			
			border: none;

			// DESKTOP
			@include mq(l) {
				border-top-right-radius: 0px;
				border-bottom-right-radius: 0px;
			}
		}
	}		

	// Botão
	.btn {
		float: left;
		width: 120px;
		height: 40px;
		padding: 0px;
		font-size: 14px;

		// DESKTOP
		@include mq(l) {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		// TABLET
		@include mq(m) {
			width: 100%;
		}

		// MOBILE
		@include mq(s) {
			width: 100%;
		}
	}
}

// Atendimento
.footer-atendimento {
	padding-left: 40px;

	// TABLET
	@include mq(m) {
		padding-left: 20px;
	}

	// MOBILE
	@include mq(s) {
		padding-left: 0px;
	}

	.footer-lista li {
		width: 100%;
	}
}

// Barra footer
.barra-footer {
	background: darken($cor_primaria, 1%);
	padding: 10px 0;
	font-size: 15px;
	font-weight: 300;
	line-height: 1.2;
	color: #FFF;

	[class^="grid-"]:not(.redes-sociais) {
		padding-top: 10px;
	}

	// Copyright
	.copyright {
		text-align: center;
	}

	// Desenvolvimento
	.development {
		text-align: right;

		// MOBILE
		@include mq(s) {
			text-align: center;
		}

		a {
			color: #FFF !important;

      &:hover{
        color: $cor_secundaria !important;
      }
		}
	}
}

// Redes sociais
.redes-sociais {
	text-align: left;

	// MOBILE
	@include mq(s) {
		text-align: center;
	}

	// Item
	a {
		display: inline-block;
		width: 40px;
		height: 40px;
		line-height: 43px;
		border-radius: 50%;
		text-align: center;
		margin: 0 5px;
		transition: .3s ease-out;

    &:first-child{
      margin-left: -10px;
    }

		i {
			color: #FFF;
			font-size: 20px;
			transition: .3s ease-out;
		}
		&:hover i {
			color: $cor_secundaria;
		}
	}
}
