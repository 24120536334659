// Vídeo caixa
.video-caixa {
	margin-bottom: 20px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	height: 360px;
	overflow: hidden;
  background-color: darken($cor_primaria,1%);

	// TABLET
	@include mq(m) {
		height: 310px;
	}

	// MOBILE
	@include mq(s) {
		height: auto;
	}

	// Vídeo
	iframe {
		width: 100%; 
		height: 300px;

		// MOBILE
		@include mq(s) {
			height: 240px;
		}

		// TABLET
		@include mq(m) {
			height: 250px;
		}
	} 

  &.maior{
    height: 500px;
    // TABLET
    @include mq(m) {
      height: 400px;
    }
    // MOBILE
    @include mq(s) {
      height: auto;
    }
    // Vídeo
    iframe {
      width: 100%; 
      height: 440px;

      // MOBILE
      @include mq(s) {
        height: 290px;
      }

      // TABLET
      @include mq(m) {
        height: 340px;
      }
    } 
  }

	// Título
	h2 {
		float: left;
		width: 100%;
		padding: 10px 20px;	
		color: $cor_fonte_escura;
		font-size: 18px;
    text-align: center;
    transition: .3s;

		// MOBILE
		@include mq(s) {			
			font-size: 16px;
			padding: 10px;
		}

		// TABLET
		@include mq(m) {
			font-size: 16px;
			padding: 10px;
		}
	}

  &:hover h2{
    color: $cor_secundaria;
  }
}
