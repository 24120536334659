// Seção
.contador-home {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: saturation;

    // Máscara
    .mascara {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, .9);
    }


    // Container
    ul {
        text-align: center;

        // Item
        li {
            display: inline-block;
            width: 100%;
            max-width: 250px;

            // TABLET
            @include mq(m) {
                max-width: 220px;
            }

            // MOBILE
            @include mq(s) {
                max-width: 100%;
                width: calc(49% - 20px);
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 20px;
            }

            // Número
            h2 {
                color: #FFF;
                font-size: 30px;
                font-weight: bold;
                margin-bottom: 10px;
                margin-left: -30px;
                font-family: $fonte_titulo;
                
                // TABLET
                @include mq(m) {
                    font-size: 36px;
                    margin-left: -30px;
                }
                
                // MOBILE
                @include mq(s) {
                    font-size: 36px;
                    margin-bottom: 0px;
                    margin-left: -30px;
                }

                span {
                    color: $cor_secundaria;
                }
            }

            // Descrição
            h3 {
                color: #FFF;
                font-size: 20px;
                font-weight: normal;

                // TABLET
                @include mq(m) {
                    font-size: 16px;
                }

                // MOBILE
                @include mq(s) {
                    font-size: 16px;
                }
            }
        }
    }
}
